<template>
  <!-- 表单管理 -->
  <div class="formment-box">
    <div class="box weixin">
      <div class="top-nav">
        <div class="title">小程序设置</div>
      </div>
      <div class="search-box">
        <el-form ref="form" inline :model="weixinlist" label-width="80px">
          <!-- <div> -->
          <el-form-item label="标题:">
            <el-input
              v-model="weixinlist.app_title"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
          <!-- </div> -->
          <div>
            <el-form-item label="邮箱地址:">
              <el-input
                v-model="weixinlist.app_email"
                placeholder="请输入邮箱"
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="图片:">
              <el-upload
                class="avatar-uploader"
                :action="url"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <img
                  v-if="weixinlist.app_pic"
                  :src="weixinlist.app_pic"
                  class="avatar"
                  style="width: 100px; height: 100px"
                />

                <div
                  style="
                    width: 100px;
                    height: 100px;
                    border: 1px solid #ccc;
                    display: flex;
                    color: #ccc;
                    flex-direction: column;
                    border-radius: 5px;
                  "
                  v-else
                >
                  <i
                    class="el-icon-plus"
                    style="font-size: 50px; line-height: 100px"
                  ></i>
                </div>
              </el-upload>
            </el-form-item>
          </div>

          <div>
            <el-form-item label="跳转地址:">
              <el-input
                v-model="weixinlist.app_url"
                placeholder="请输入跳转地址"
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="appId:">
              <el-input
                v-model="weixinlist.app_id"
                placeholder="请输入微信appId"
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="密钥:">
              <el-input
                v-model="weixinlist.app_secret"
                placeholder="请输入微信密钥"
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="申请商品:">
              <el-button @click="catedialog = true" v-if="!goods_id">请选择商品</el-button>
              <el-button @click="catedialog = true" v-else>已选商品</el-button>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary" @click="savelist">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog title="请选择商品" :visible.sync="catedialog" width="30%">
      <infoTable
        :selection="false"
        :tableHeadConfig="tableHead"
        :tableLoadData="cateaArr"
        v-infinite-scroll="load"
        style="height:400px;overflow-y: auto;"
      >
        <template v-slot:flag="slotData">
          <el-checkbox v-model="slotData.data.row.flag"></el-checkbox>
        </template>
        <template v-slot:pic="slotData">
          <img style="width: 40px; height: 40px" :src="slotData.data.row.pic" />
        </template>
      </infoTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="catedialog = false">取 消</el-button>
        <el-button type="primary" @click="choosegoods">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
export default {
  mounted() {
    this.platform_id = sessionGetKey("platformId");
    this.url = this.$uploadurl + `?platform_id=${this.platform_id}`;

    this.getlist();
    this.getcate();
  },
  data() {
    return {
      url: "",
      page:1,
      catedialog: false,
      weixinlist: {},
      platform_id: "",
      cateaArr: [],
      goods_id:null,
      tableHead: [
        {
          label: "",
          columnType: "slot",
          slotName: "flag",
        },
        {
          label: "ID",
          field: "id",
          sort: true,
        },
        {
          label: "产品分类",
          field: "name",
        },
        {
          label: "分类图片",
          columnType: "slot",
          slotName: "pic",
        },
      ],
    };
  },
  methods: {
    load(){
      this.page+=1
      // this.getcate()
      console.log(this.page)
    },
    choosegoods() {
      let arr=[]
      let obj=new Object()
      this.cateaArr.forEach((val,index)=>{
        if(val.flag){
          obj[arr.length+1]=val.id
         arr.push(obj[arr.length+1]=val.id) 
        }
      })
      if(!arr.length){
        this.goods_id=null
      }else{
       this.goods_id=obj
      }
      
      console.log(arr,obj)
      this.catedialog = false;
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      if (res.code == 0) {
        this.weixinlist.app_pic = res.data;
      }
    },
    savelist() {
      console.log(this.weixinlist);
      let that = this;

      that
        .$request({
          url: that.$api.appMall.setweixin,
          method: "post",
          data: {
            app_id: this.weixinlist.app_id,
            app_secret: this.weixinlist.app_secret,
            app_pic: this.weixinlist.app_pic,
            app_email: this.weixinlist.app_email,
            app_url: this.weixinlist.app_url,
            app_title: this.weixinlist.app_title,
            goods_id:JSON.stringify( this.goods_id),
          },
          params: {
            platform_id: that.platform_id,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            that.$message.success("保存成功");
            that.weixinlist = res.data[0];
          }
        });
    },
    getcate() {
      let that = this;
      that
        .$request({
          url:that.$api.goodClass.getClass,
          // url: that.$api.good.getGood,
          method: "post",
          params: {
            platform_id: that.platform_id,
              //  page: this.page,
              //       name:'',
              //       cate:'',
              //       start_time:'',
              //       end_time:''
          },
        })
        .then((res) => {
          let arr = [];
          if (Array.isArray(res.data)) {
            arr = res.data;
          } else {
            arr = Object.values(res.data);
          }
          let goods_id=''
          if(that.weixinlist.goods_id){
          goods_id=  JSON.parse(JSON.stringify(that.weixinlist.goods_id))
          }
          console.log(goods_id)
        arr.filter((val) => {
          if(goods_id&&goods_id.indexOf(val.id)!=-1){
            this.goods_id=goods_id
val.flag = true;
          }else{
val.flag = false;
          }
            
          });
          that.cateaArr.push(...arr);
          
          console.log(that.cateaArr);
        });
    },
    getlist() {
      const that = this;
      that
        .$request({
          url: that.$api.appMall.getweixin,
          method: "post",
          data: {},
          params: {
            platform_id: that.platform_id,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            let d = res.data.platform[0];
            d.app_id = res.data.wxconfig[0].app_id;
            d.app_secret = res.data.wxconfig[0].app_secret;
            
            that.weixinlist = d;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.formment-box {
  height: 100%;
  padding: 66px;
  min-height: 600px;

  /deep/.el-input__inner {
    width: 300px;
  }
/deep/.el-table_1_column_1{
  &:first-child{
    width: 100px;
     text-align: center;
  }
 
}
  .box {
    border-radius: 16px;

    overflow: hidden;

    width: 100%;
    min-height: 100%;

    background: #fff;
    border-radius: 16px;
    padding: 33px;

    .top-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        padding-bottom: 10px;
        color: #4458fe;
        font-size: 20px;
        font-weight: 600;
        border-bottom: #4458fe 2px solid;
      }
    }

    .search-box {
      margin: 21px 0;
      // display: flex;
      align-items: center;
    }
  }
  .weixin {
    .el-dialog__wrapper {
      /deep/.el-dialog__body {
        padding: 20px !important;
      }
    }
  }
}
</style>
